import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {AppRouterService} from '../../../services';
import {FeatureToggleService} from '../services/feature-toggle.service';

@Injectable()
export class FeatureToggleGuard  {

  constructor(
    private readonly featureToggleService: FeatureToggleService,
    private readonly appRouterService: AppRouterService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canView(next.data.features);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean> | Promise<boolean> | boolean {
    const features = route.data && route.data.features;
    if (!features) {
      return true;
    }
    return this.canView(features);
  }

  private canView(features: string | string[]): Observable<boolean> {
    return this.featureToggleService.isFeatureEnabledAsync(features)
      .pipe(
        tap(isEnabled => {
          if (!isEnabled) {
            this.appRouterService.navigateToLogin();
          }
        }),
      );
  }

}
