import {Observable} from 'rxjs';

export const FEATURE_TOGGLE_STATE_KEY = 'transfer-state-feature-toggle';

export abstract class FeatureToggleLoaderService {
  abstract getConfig(): Observable<any>;
}

export interface FeatureToggle {
  [key: string]: boolean;
}
