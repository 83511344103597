import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, shareReplay, take, takeUntil} from 'rxjs/operators';

import {FeatureToggle, FeatureToggleLoaderService} from '../models/feature-toggle';

@Injectable()
export class FeatureToggleService {

  private features$: BehaviorSubject<FeatureToggle | null>;
  private destroyStream$: Subject<void>;

  featuresList$: Observable<FeatureToggle | null>;

  constructor(
    private readonly featureToggleLoaderService: FeatureToggleLoaderService,
  ) { }

  init(): void {
    this.features$ = new BehaviorSubject(null);
    this.featuresList$ = this.features$.asObservable().pipe(shareReplay(1));
    this.destroyStream$ = new Subject<void>();
    this.featureToggleLoaderService.getConfig().pipe(
      take(1),
      takeUntil(this.destroyStream$),
    ).subscribe(res => this.features$.next(res));
  }

  destroy(): void {
    this.destroyStream$.next();
    this.destroyStream$.complete();
    this.features$.complete();
  }

  isFeatureEnabled(feautureName: keyof FeatureToggle | (keyof FeatureToggle)[]): boolean {
    const features = this.features$.value;
    if (!features) {
      return false;
    }
    if (Array.isArray(feautureName)) {
      return feautureName.some(name => !!features[name]);
    } else {
      const featureValue = features[feautureName];
      return featureValue;
    }

  }

  isFeatureEnabledAsync(feautureName: keyof FeatureToggle | (keyof FeatureToggle)[]): Observable<boolean> {
    return this.features$.pipe(
      map(() => this.isFeatureEnabled(feautureName)),
    );
  }
}
